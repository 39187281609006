import React from "react"
import { StaticQuery, graphql } from "gatsby"

const renderLink = linkItem => {
  switch (linkItem.target) {
    case "_blank":
      return (
        <a
          target="_blank"
          rel="noreferrer noopener"
          href={linkItem.url}
          title={linkItem.label}
        >
          {linkItem.label}
        </a>
      )
    default:
      return (
        <a href={linkItem.url} title={linkItem.label}>
          {linkItem.label}
        </a>
      )
  }
}

const BottomMenu = () => {
  return (
    <StaticQuery
      query={graphql`
        query BottomMenuQuery {
          allWpMenu(filter: { slug: { eq: "bottom-navigation" } }) {
            edges {
              node {
                id
                menuItems {
                  nodes {
                    url
                    label
                    target
                    id
                    cssClasses
                  }
                }
                slug
                name
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <ul
            name={data.allWpMenu.edges[0].node.name}
            className={`menu menu-${data.allWpMenu.edges[0].node.slug}`}
          >
            {data.allWpMenu.edges[0].node.menuItems.nodes.map(menuItem => {
              return (
                <li
                  key={menuItem.id}
                  className={`post post-id-${
                    menuItem.id
                  } ${menuItem.cssClasses.join(" ")}`}
                >
                  {renderLink(menuItem)}
                </li>
              )
            })}
          </ul>
        )
      }}
    />
  )
}

export default BottomMenu
