import React, { useState, useEffect } from "react"
import { StaticQuery, graphql } from "gatsby"
import { pageStore } from "state/store-zustand";

import BottomMenu from "./bottomMenu/bottomMenu"
import LogoIcon from "../../images/svg/logoIcon.svg"

import "./footer.scss"

const today = new Date()
const year = today.getFullYear()

const renderMenuItems = menuItems => {
  const items = []

  menuItems.nodes.forEach((e, i) => {
    if (e.parentDatabaseId === 0) {
      if (e.childItems) {
        items.push(
          <li
            key={`${i}`}
            className={`post post-id-${e.id} ${e.cssClasses.join(" ")}`}
          >
            {renderLink(e)}
            <ul className="sub-menu">{renderSubMenuItems(e.childItems)}</ul>
          </li>
        )
      } else {
        if (!e.parentId) {
          items.push(
            <li
              column is-4-tablet is-4-mobile
              key={`${i}`}
              className={`post post-id-${e.id} ${e.cssClasses.join(" ")}`}
            >
              {renderLink(e)}
            </li>
          )
        }
      }
    }
  })
  return items
}

const renderSubMenuItems = menuItems => {
  const items = []
  menuItems.nodes.forEach((e, i) => {
    items.push(
      <li
        key={`${i}`}
        className={`post post-id-${e.id} ${e.cssClasses.join(" ")}`}
      >
        {renderLink(e)}
      </li>
    )
  })
  return items
}

const renderLink = linkItem => {
  switch (linkItem.target) {
    case "_blank":
      return (
        <a
          target="_blank"
          rel="noreferrer noopener"
          href={linkItem.url}
          title={linkItem.label}
        >
          {linkItem.label}
        </a>
      )

    case null:

      if (linkItem.url.includes(`activity`)) {
        linkItem.url = linkItem.url.replace(`/features/activity/`, `/featured/`)
      }

      return (
        <a
          rel="noreferrer noopener"
          href={linkItem.url}
          title={linkItem.label}
        >
          {linkItem.label}
        </a>
      )
    default:
      const link = linkItem.url.replace(`https://vibemap.com`, ``)

      return (
        <a href={link} title={linkItem.label}>
          {linkItem.label}
        </a>
      )
  }
}

const Footer = ({ ...props }) => {

  // TODO: use zustand and reduce 1 render
  const [isClient, setClient] = useState(false);
  const showFooter = pageStore(state => state.showFooter)

  useEffect(() => {
    setClient(true)
  }, [])

  //if (!isClient) return null

  const footerStyles = {
    display: 'block',
    paddingTop: '2rem'
  }

  if (showFooter === false) {
    return null
  }

  return (
    <footer
      className={props.className ? props.className : 'footer'}
      styles={footerStyles}>
      <div className="container footer-menu">
        {props.hasTopRule && <hr />}
        <div className="columns is-gapless is-flex">
          <div className="column is-12 ">
            <StaticQuery
              query={graphql`
                query FooterMenuQuery {
                  allWpMenu(filter: { slug: { eq: "footer-navigation" } }) {
                    edges {
                      node {
                        id
                        slug
                        name
                        menuItems {
                          nodes {
                            parentDatabaseId
                            url
                            label
                            target
                            id
                            cssClasses
                            childItems {
                              nodes {
                                parentId
                                url
                                label
                                target
                                id
                                cssClasses
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              `}
              render={data => {
                return (
                  <div className='container'>
                    <ul

                      name={data.allWpMenu.edges[0].node.name}
                      className={` menu menu-${data.allWpMenu.edges[0].node.slug}`}

                    >
                      {renderMenuItems(data.allWpMenu.edges[0].node.menuItems)}
                    </ul>
                  </div>
                )
              }}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="container bottom">
        <div className="columns is-flex is-flex-tablet">
          <div className="column is-2-desktop is-6-mobile is-3-tablet no-wrap"><span>&copy; Vibemap, {year}</span></div>
          <div className="column is-8-desktop is-4-mobile is-7-tablet">
            <BottomMenu />
          </div>
          <div className="column is-2 has-text-right">
            <LogoIcon />
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
